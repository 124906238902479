<template>
    <centered-layout :loading="pageLoading" loading-message="Checking Activation Code">
        <w-auth-password-form
            :custom-rules="passwordRules"
            :description-text="description"
            :errors="activationErrors"
            :loading="loading"
            :logo-src="clientLogo"
            :title="title"
            :url-params="urlParams"
            @change="setPassword"
            @submit="submit"
            submit-text="Activate"
            v-if="valid && !activated"
        >
            <v-flex xs12>
                <w-password-requirements
                    :password="password"
                    :requirements="passwordOptions"
                ></w-password-requirements>
            </v-flex>
        </w-auth-password-form>
        <w-form-message :logo-path="clientLogo" :title="title" v-else-if="!valid">
            The activation code is invalid, please check you have entered it correctly or that
            it has not expired.
            <p>
                <router-link :to="{name: loginPage}">Do you want to login?</router-link>
            </p>
        </w-form-message>
        <w-form-message :logo-path="clientLogo" :title="title" v-else-if="activated">
            <p>Your account has been activated.</p>
            <p>
                <router-link :to="{name: loginPage}">Do you want to login?</router-link>
            </p>
        </w-form-message>
    </centered-layout>
</template>

<script>
    import {LOGIN_PAGE} from '@/router/route-names'
    import {mapActions, mapGetters} from 'vuex'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import WAuthPasswordForm from '@windsor-telecom/windsor-framework/src/components/WAuthPasswordForm'
    import WPasswordRequirements from '@windsor-telecom/windsor-framework/src/components/WPasswordRequirements'
    import WFormMessage from '@wf/components/WFormMessage'
    import WValidationMixin from '@wf/mixins/forms/w-validation-mixin'
    import PasswordMixin from '@/mixins/password-mixin'

    export default {
        name: 'ActivationPage',
        mixins: [WValidationMixin, PasswordMixin],
        metaInfo () {
            const vm = this
            return {
                title: vm.dataLoading ? 'Loading Activation' : vm.title
            }
        },
        data: () => ({
            description: 'Set your new password below to complete activation.',
            activationErrors: [],
            loginPage: LOGIN_PAGE,
            pageLoading: false,
            loading: false,
            activated: false,
            valid: false
        }),
        components: {
            CenteredLayout,
            WAuthPasswordForm,
            WPasswordRequirements,
            WFormMessage
        },
        props: {
            userId: {
                type: String
            },
            activationCode: {
                type: String
            }
        },
        methods: {
            ...mapActions('auth', ['checkActivationCode', 'activateUser']),
            submit (data) {
                const vm = this
                vm.loading = true
                vm.activateUser(data).then(() => {
                    vm.loading = false
                    vm.activated = true
                }, () => {
                    vm.loading = false
                })
            }
        },
        computed: {
            ...mapGetters('auth', ['clientLogo', 'clientName']),
            ...mapGetters('user', {passwordOptions: 'userPasswordRequirements'}),
            title () {
                const vm = this
                const text = vm.valid ? 'Activate Account' : 'Invalid Activation Code'
                return `${text} - ${vm.clientName}`
            },
            passwordRules () {
                const vm = this
                return [
                    vm.validationRules.generic.required,
                    vm.validationRules.password.minLength,
                    vm.validationRules.password.minNumbers,
                    vm.validationRules.password.minSymbols,
                    vm.validationRules.password.minLowercase,
                    vm.validationRules.password.minUppercase
                ]
            },
            urlParams () {
                const vm = this
                return {
                    userId: vm.userId,
                    activationCode: vm.activationCode
                }
            },
            dataLoading () {
                const vm = this
                return vm.pageLoading || vm.loading
            }
        },
        created () {
            const vm = this
            vm.pageLoading = true
            vm.checkActivationCode(vm.urlParams).then(() => {
                vm.valid = true
                vm.pageLoading = false
            }, (response) => {
                vm.activationErrors = response.errors
                vm.pageLoading = false
            })
        }
    }
</script>
